:root {
    /* --header-height: 3rem; */
    --nav-width: 65px;
    --first-color: #151C25;
    --first-color-light: #AFA5D9;
    --white-color: #F7F6FB;
    --admin-color: #82C5FB;
    --body-font: 'Nunito', sans-serif;
    --normal-font-size: 1rem;
    --z-fixed: 100
}

*,
::before,
::after {
    box-sizing: border-box
}
a {
    text-decoration: none
}
.header {
    width: 100%;
    height: var(--header-height);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    background-color: #212730;
    z-index: var(--z-fixed);
    transition: 1s
}
.header_toggle {
    color: var(--first-color);
    font-size: 1.5rem;
    cursor: pointer
}
.header_img {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden
}
.header_img img {
    width: 40px
}
.l-navbar {
    position: fixed;
    top: 0;
    left: -60%;
    width: var(--nav-width);
    height: 100vh;
    background-color: var(--black-color);
    padding: .5rem 1rem 0 0;
    transition:  .5s;
    z-index: var(--z-fixed)
}
.l-navbar:hover {
  
    width: auto;
   
}
.nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden
}
.nav_logo,
.nav_link {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    column-gap: 1rem;
    padding: .5rem 8px .5rem 22px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

}
.nav_logo {
    margin-bottom: 2.5rem;
}
.nav_logo-icon {
    font-size: 1.25rem;
    color: var(--white-color)
}
.nav_logo-name {
    color: var(--white-color);
    font-weight: 700
}
.nav_link {
    position: relative;
height: 54px;
    color: var(--first-color-light);
    margin-bottom: 1rem;
    transition: .3s
}
.nav_link:hover {
    color: var(--primary-color);
    background-color: rgb(13 88 226 / 22%);    
}

.nav_icon {
     font-size: 1.25rem 
}
.l-navbar svg path {
    stroke: var(--white-color);
}
.l-navbar .show {
    left: 0
}
.body-pd {
    padding-left: calc(var(--nav-width) + 1rem)
}
.l-navbar .active:not(.sub-nav .nav_link), 
.l-navbar .sub-active{
    color: var(--primary-color)
}

.l-navbar .active svg path{
    stroke: var(--primary-color);
}
.l-navbar .active:not(.sub-nav .nav_link)::before {
    content: '';
    position: absolute;
    left: 0;
    width: 5px;
    height: 54px;
    background-color: var(--primary-color)
}
.height-100 {
    height: 100vh
}
.badge{
	top: 5px;
}
hr {
    margin: 0;
    margin-left: 20px;
    background-color: white;
}
img.menu_mobile {
    display: none;
}

@media screen and (min-width: 768px) {
    body {
        margin: calc(var(--header-height) + 1rem) 0 0 0;
        padding-left: calc(var(--nav-width) + 2rem)
    }

    .header {
        height: calc(var(--header-height) + 1rem);
        padding: 0 2rem 0 calc(var(--nav-width) + 2rem)
    }

    .header_img {
        width: 40px;
        height: 40px
    }

    .header_img img {
        width: 45px
    }

    .l-navbar {
        left: 0;
        padding: 1rem 1rem 0 0;
    }

    .l-navbar .show {
        width: calc(var(--nav-width) + 156px)
    }

    .body-pd {
        padding-left: calc(var(--nav-width) + 188px)
    }
}

@media screen and (max-width: 768px) {
    .header_toggle {
        width: 30px;
        height: 30px;
    }
    img.menu_mobile {
        width: 32px;
        margin: 15px;
        display: block;
    }
    .shows {
        left: 0px!important;
        width: auto!important;
    }
    .leftspace {
        position: absolute;
        right: 30px;
        top: 5px;
        transition: all 1s ease-in-out;
    }
}