.slider{
    height: 400px;
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 300px;
    right: 0;
    width: 100%;
    float: right;
  }
  .titreE p {
    padding-left: 50px;
    position: relative;
    font-size: 23px;
    color: var(--white-color)
  }
.more-info{
  padding-right: 16%;
    text-align: right;
    font-size: 16px;
    color: var(--primary-color);
    line-height: 5;
    font-weight: 600;
  } 
  .owl-theme .owl-nav [class*='owl-']{
    border-radius: 0 !important;
  }
  .titreE p::after {
    content: '';
    position: absolute;
    background-color: rgb(13, 88, 226);
    left: 50px;
    bottom: -5px;
    width: 40px;
    height: 3px;
    border-radius: 5px;
  }
  /* .e-learning{
    background: linear-gradient(90deg, #f9f9f900 10%, #151c25 10%);
    position: absolute;
    height: 45%;
  } */
  .row{
    position: relative;
    /* margin-bottom: 38px; */
  }
  .e-learning::before {
    content: '';
    background: #151c25;
    width: 90%;
    height: 85%;
    position: absolute;
    top: 0;
    left: 10%;
    z-index: -1;
  }
  /* .e-learning.disable::before {
    content: none;
  } */
  .owl-carousel  button.owl-prev, .owl-carousel .owl-nav button.owl-next {
      background: none;
       color: var(--primary-color);
      border: 1px solid var(--primary-color) !important;
      padding: 9px !important;
      /* font: inherit; */
      width: 40px;
      height: 40px;
      line-height: 0.4 !important;
      border-radius: 0;
  }
  .owl-carousel  button.owl-prev span, 
  .owl-carousel .owl-nav button.owl-next span{
  font-size: 30px;
      color: var(--primary-color); 
      
  }
  .owl-nav{
    position: absolute;
      top: -70px;
      right: 0;
  }
  @media only screen and (max-width: 1440px) {
    .more-info{
      padding-right: 30%;
        text-align: right;
        font-size: 14px;
        color: var(--primary-color);
        line-height: 5;
      } 
  }
  @media only screen and (max-width: 1200px) {
    .titreE p {
      font-size: 19px;
    }
  }
  @media only screen and (max-width: 992px) {
    .owl-carousel button.owl-prev, .owl-carousel .owl-nav button.owl-next {
      width: 35px;
      height: 35px;
    }
    .more-info {
      padding-right: 38%;
    }
    .e-learning::before {
      height: 75%;
    }
  }
 
/*******************PUSH 11-05-22*****/
@media only screen and (max-width: 426px) {
  .e-learning::before{
    content:none;
  }
  .e-learning .row {
    flex-direction: column !important;
  }
  .titreE p {
    padding-left: 0px !important;
    font-size: 23px !important;
    line-height: 28px;
    font-weight: 600;
    
  }
   .titreE p::after{
     left: 0px !important;
    
   } 
   .offset-1{
     margin-left: 0px !important;
   }
   .more-info {
    padding-right: 31% !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}
} 

  