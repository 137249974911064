.bunner{
    margin-top: 65px;
    margin-left: -40px;
    position: relative;
    padding-left: 65px!important;
    background: linear-gradient(90deg, rgba(30,46,68,1) 0%, rgba(17,57,108,1) 62%, rgba(1,111,253,1) 100%);
}
.bunner .image{
        position: absolute;
        top: -87px;
        right: 10%;
        width: 160px !important;
}
.bunner .imageElearning{
    position: absolute;
    top: -115px;
   width: 33% !important;
}
.bunner .imageWebinar{
    position: absolute;
     top: -100px; 
   width: 30% !important;
}
.bunner .imagePresentiel{
    position: absolute;
     top: -100px; 
   width: 18% !important;
  right: 10%;
}

.bunner .owl-dots{
    margin-top: 10px;
    float: left;
    margin-left: 8.5%;
}
.owl-dots .owl-dot span{
    border-radius: 0!important;
}
.bunner-title{
    color: white;
    text-transform:uppercase;
}
.bunner-title span{
    color: var(--primary-color); 
    margin-left: 40px;
}
.bunner .plus-right{
display: inline!important;
padding-left: 13px;
}

.bunner-texte h2{
    font-size: 25px;
}
.bunner li.list-inline-item:not(:last-child){
    position: relative;

}
.bunner li.list-inline-item:not(:last-child)::before{
    content: '';
    position: absolute;
    width: 2px;
    height: 12px;
    background: #6c6c6c;
    right: 5px;
    top: 14px;
}
.img{
    width:60px;
    left: 3px;
    top: 15px;
    position: absolute;
  }
.bunner .owl-carousel .owl-item img {
    display: block;
    width: 32px;
}
.bunner .owl-carousel .owl-stage-outer{
    overflow-y: inherit;
    overflow-x: clip;
} 

.bunner-title-elearning{
    top:23px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background:  #D6D6D6 !important;
}
.owl-theme .owl-dots .owl-dot span {
    background:#869791 !important ;
}
  @media only screen and (max-width: 768px) {
    .bunner{
      margin-left: 0;
      padding-left: 0;
    }
    .bunner .offset-md-1 {
      margin-left: 0;
    }
    .bunner .image {
        width: 19%;
        position: absolute;
        top: -83px;
        right: 0;
    }
  }