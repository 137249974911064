.chartContainer {
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    width: 150px;
    /* height: 200px; */
  }
  .chartInner {
    background: #151c25;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 28px;
    right: 20px;
    bottom: 18px;
    left: 19px;
    border-radius: 50%;
    padding: 1.25em 0;
    line-height: 120%;
    /* z-index: 1; */
    overflow: hidden;
    color: white;
    text-align: center;
    
  }
  .chartStatus,
  .chartValue {
    font-weight: bold;
  }
  .chartTarget,
  .chartDaysLabel {
    font-size: 0.75em;
  }
  .chartDaysRemaining {
    color: #f90;
    font-weight: bold;
    margin-top: 0.25em;
  }
  
  /* Generic styles */
  .App {
    font-family: sans-serif;
    text-align: center;
  }
  @media screen and (max-width:1244px) {
    .body-details .rest{
      flex-direction: column !important;
    }
     .chartContainer {
      max-width: 67% !important;
    }
    
  }
  