.jumbotron{
    background-color:var(--first-color);
}
.jumbotron .box{
    background: url('../../assets/plusbg.png');
        background-repeat: no-repeat;
        background-size: 58px;
    background-position: 98% 99%;
}
.footerDoc p{
    text-align: right;
}
.icon-plus{
    size: 30px;
}
.icon-plusss{
width: 30px;
}
.jumbotron .box p{
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    font-family: 'Montserrat';
}
.boxTitle{
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    letter-spacing: -2%;
}
.jumbotron .box {
    font-family: 'Montserrat'; 
   
}
@media screen and (max-width:426px) {
    .jumbotron .box{
        background: #151C25 !important;
          
    }
    .search-title {
    font-size: 28px;
    line-height: 34px;
    font-weight: 800;
    text-transform: uppercase;
    text-overflow: clip;
  /*  white-space: nowrap;*/
    overflow: hidden;
    letter-spacing: -2%;
    }
}
