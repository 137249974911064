.bg-dark {
    --bs-bg-opacity: 1;
    background-color: var(--black-color) !important;
}
.h4-size h4{
    font-size: 28px;
    font-weight: 600;
}
.form-aria{
    background-color: #212730;
}
.cost{
    background-image: url('../../assets/icons/euro.png') !important;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
}
.date-select{
    background-image: url('../../assets/icons/CalendarBlue.svg') !important;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
}


.dashed{
    border: dashed !important;
    border-width: 0.7px !important;
    border-color: #fff !important;
}
.dashed:focus {
    border: dashed !important;
    border-width: 0.7px !important;
    border-color: #fff !important;
}
.lists p{
    font-size: 19px;
}
.height43{
    height: 43px;
}
.square{
  /*  margin-left: 7px;*/
    height: 43px;
    width: 43px;
    border: 1px solid var(--primary-color);
    text-align: center;
    line-height: 40px;
}
.square img {
   /* width: 20px;
    height: 18px;
    margin:auto;*/
}
.space-evenly{
    justify-content: space-evenly;
}
.square button{
    padding-left: 6px;
}
.lists-filter .input-group-append .btn-outline-dark {
    background-color: var(--input-color);
    border: 1px solid var(--input-color);
}
.card:hover .card-body{
    background-color: transparent;
}
.accordion-item:first-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.accordion-item {
    padding: 20px 20px 0;
    margin-bottom: 25px;
    background-color: var(--card-color);
    border: 0;
}
.accordion-header{
    font-family: 'Montserrat';
}
.accordion-button{
    background-color: var(--card-color);
    display: block;
    color: var(--white-color);
}
.accordion-header{
    position: relative;
}
.dropright {
    position: absolute;
    /* align-items: center; */
    /* display: flex; */
    top: 37px;
    right: -10px;
    z-index: 999999999999;
}
.nostyle {
    background: none;
    border: none;
    padding: 0px!important;
}
.nostyle img {
    margin-top: -20px;
    margin-right: 15px;
}
.nostyle .dropdown-menu-right{
    width: 200px !important;
}
.dropref select{
    background-image: url('../../assets/icons/ChevronDown.svg') !important;
    background-size: 1.25rem;
}
.accordion-button .header-text{
    font-size: 19px;
    font-weight: 700;
    text-transform: capitalize;
}
.form-controlinput{
    background-color: #3a4048;
    padding: 5px 5px;
    color: white;
    width: 100px;
    font-weight: bold;
    border: none;
    border-bottom: none;
    
}
.accordion-button:focus {
    box-shadow: none;
}
.accordion-button:not(.collapsed) {
    color: var(--white-color);
    background-color: var(--card-color);
    box-shadow: none;
}
.accordion-info{
    font-weight: 400;
    font-size: 14px !important;
    font-family: 'Montserrat';
}
.accordion-body .card-body, .accordion-body .card{
    background-color: var(--secondary-color);
}
.lists .card-footer .d-flex{
    justify-content: end;
}
.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    line-height: 1.4;
    margin: 2rem;
    color: #212529;
  }
  input {
    background-color: #0c74db;
    border: none;
    color: white;
    padding: 8px 8px;
   
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 4px;
    margin: 0rem 0rem 0rem 0rem;
  }
  
  input::placeholder {  
    text-align: left;
}

 .area.form-control{
border-color: #CCCCCC !important;
border: solid 1px !important;
background-color: #151C25 !important;
 }
  .editor{
    display: inline-block;
    
  }
  .tox-tinymce {
    background-color: #151C25 !important;
  }
  .squarevisulaisation{
    height: 55px !important;
    width: 43px;
    border: 1px solid var(--primary-color);
    text-align: center;
    line-height: 40px;
  }
  .webinars .owl-carousel .owl-item img{
    width: 24px;
    height: 24px;
}

