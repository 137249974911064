.search input{
  background: url('../../assets/icons/greySearch.svg');
  background-repeat: no-repeat;
  /* background-size: 3%;  */
  background-position: left;
 

  padding: 20px 20px 20px 40px!important; 
  margin-top: 30px;
  outline: none;
   background-color: transparent;
   border: 0;
   
   border-radius: 0px;
   width: 100%;
   font-size: 40px;
   font-weight: 700;
   color:#fff;

}
.search input::placeholder{
 font-size: 40px;
  font-weight: 700;
  font-style: normal !important;
  padding-left: 30px;
}

.nav-icon{
  position: absolute;
  margin-top: 10px;

}
.nav_icon{
  color:#64686E;
}
.searchinput {
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  border-bottom: 1px solid #ffffff70;
}
.search .form-control:focus {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}
.lesplusrecherches {
  display: absolute;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  
}
.titre{
  display: absolute;
  padding: 0%;
}
.pagination.pagination-lg{
  float: right;
  padding-right: 20px;
}