:root {
    /* --header-height: 3rem; */
    --nav-width: 65px;
    --first-color: #151C25;
    --first-color-light: #AFA5D9;
    --white-color: #F7F6FB;
    --body-font: 'Nunito', sans-serif;
    --normal-font-size: 1rem;
    --z-fixed: 100
}

*,
::before,
::after {
    box-sizing: border-box
}

/* body {
    position: relative;
    margin: var(--header-height) 0 0 0;
    padding: 0 1rem;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    transition: .5s
} */

a {
    text-decoration: none
}
.header {
    width: 100%;
    height: var(--header-height);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    background-color: #212730;
    z-index: var(--z-fixed);
    transition: 1s
}

.adminTab{
    bottom: 0;
    position: absolute;
}
.header_toggle {
    color: var(--first-color);
    font-size: 1.5rem;
    cursor: pointer
}

.header_img {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden
}

.header_img img {
    width: 40px
}

.l-navbar {
    position: fixed;
    top: 0;
    left: -30%;
    width: var(--nav-width);
    height: 100vh;
    background-color: var(--black-color);
    padding: .5rem 1rem 0 0;
    transition:  .5s;
    z-index: var(--z-fixed)
}

.l-navbar:hover {
  
    width: auto;
   
}
.nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden
}

.nav_logo,
.nav_link {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    column-gap: 1rem;
    padding: .5rem 8px .5rem 22px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

}

.nav_logo {
    margin-bottom: 2.5rem;
    padding-left: 0;
}

.nav_logo-icon {
    font-size: 1.25rem;
    color: var(--white-color)
}

.nav_logo-name {
    color: var(--white-color);
    font-weight: 700
}

.nav_link {
    position: relative;
    color: var(--white-color);
    margin-bottom: 1.5rem;
    font-weight: 400;
    font-size: 16px;
    transition: .3s
}

.nav_link:hover {
    color: var(--primary-color);
    background-color: rgb(13 88 226 / 22%);
}

.nav_icon {
     font-size: 1.25rem 
}

.l-navbar .show {
    left: 0
}

.body-pd {
    padding-left: calc(var(--nav-width) + 1rem)
}

.l-navbar .active {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 16px;
   
}


.l-navbar .active::before {
    content: '';
    position: absolute;
    left: 0;
    width: 5px;
    height: 54px;
    background-color: var(--primary-color)
}

.height-100 {
    height: 100vh
}

.badge{
	top: 4px;
    left: 21px !important;
}

.nav_list{
    overflow-y: auto;
    height: calc(100vh - 6rem);
}
.notify{
    width: 18px;
    height: 20px;
}
@media screen and (min-width: 768px) {
    body {
        margin-left: calc(var(--header-height) + 1rem) 0 0 0;
        padding-left: calc(var(--nav-width) + 2rem)
    }

    .header {
        height: calc(var(--header-height) + 1rem);
        padding: 0 2rem 0 calc(var(--nav-width) + 2rem)
    }

    .header_img {
        width: 40px;
        height: 40px
    }

    .header_img img {
        width: 45px
    }

    .l-navbar {
        left: 0;
        padding: 1rem 4px 0 0;
    }

    .l-navbar .show {
        width: calc(var(--nav-width) + 156px)
    }

    .body-pd {
        padding-left: calc(var(--nav-width) + 188px)
    }
}