.signin {
    width: 45%;
    margin: 50px auto;
    padding: 40px;
    background: black;
}
.signin .form-label {
    margin-bottom: 0.5rem;
    color: white;
}
.signin .title{
    color: white;
}
.hidden{
    display: none!important;
}
.microsoft {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin: 15px 0px;
}