.bunner-profil{
    margin-top: -4px;
    margin-left: -5px;
    padding-left: 65px;
    margin-right: 40px !important; 
    max-height: 240px;
    background: linear-gradient(90deg, rgba(1,111,253,1) 0%, rgba(17,57,108,1) 62%, rgba(30,46,68,1) 100%);
}
.bunner-profil .col .image-profil{
    position: absolute;
    right: 10%;
}
.bunner-title{
    color: white;
    text-transform:uppercase;
}
.bunner-title span{
    color: var(--primary-color); 
    margin-left: 30px;
}
.bunner-texte h2{
    font-size: 25px;
}
.titre-h-5{
    font-size: 20px; 
}
.titre-info-h-5{
    font-size: 15px;
}


.select .dropdown{
   color: white;
   background-color: #212730;
   margin-left: -12px;
   width:23%;
   float:right;
   border: 0;
   border-radius: 0;
}
.select .dropdown svg{
    color: bleu;
 }
.bunner.card-title, .bunner.card-text{
    text-align: center;
}
.bunner-box{
    padding-top:35px
}

@media screen and (max-width:992px) {
    .bunner-profil{
        height: 33%;
    }
}
/***********Push 11-05-22***************/
@media only screen and (max-width: 768px) {
    .colCard{
        width:50%;
    }
    .bunner-profil{
        height: auto;
    }
    .bunner-profil{
        margin-right:0% !important;
    }
    }
    @media screen and (max-width:426px) {
      
        .mobile .bunner-box{
            padding-top:5px !important;
            padding-left: 10px !important;
        }
        .mobile.bunner-profil {
            height: 35% !important;
            
        }
        .mobile{
           margin-right: 0px !important; 
           margin-bottom: 20px;
        }
      
        .bunner-profil .dropdown{
            width: 100% !important;
            background-color: #3A4048 !important;
        }
        .bunner-profil .select{
            position: relative !important;
            top: -80%  !important;
        }
    }