.blocD{
    background-color:#1E2E45;
    padding: 20px ;
      
}
.progressstyle #ProgressBar{
margin-bottom: 30px;
}
.chartContainerDashboard {
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    width: 150px;
  }

  .alignCenter{
    align-items: center;
  }

@media only screen and (max-width: 999px) {
   hr{
     -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    }
   
 }

.title-bloc{
    font-family: Montserrat;
font-size: 16px;
font-weight: 600;
line-height: 19px;
letter-spacing: 0em;
color:#fff;
text-align: center;
}
.txt-bloc{
    font-family: Montserrat;
font-size: 23px;
font-weight: 400;
line-height: 28px;
letter-spacing: -0.02em;
color:#fff;
text-align: center;

}
.space-between{
    justify-content: space-between;
}
.flex-end{
    justify-content: flex-end;
}


.legend {
    color: white;
    padding: 15px;
    display: flex;
    align-items: center;
}

.legend .green {
    background: #3EA279;
    width: 30px;
    height: 10px;
    display: block;
    margin-right: 15px;
}
.legend .white {
    background: white;
    width: 30px;
    height: 10px;
    display: block;
    margin-right: 15px;
}