.unite_icone {
  margin-right: .5rem;
}
.breadcrumb-item a {
  font-weight: lighter;
  color: var(--white-color);
}
.breadcrumb-item.active {
  font-weight: bold;
  color: var(--white-color);
}
.head-details .text {
  font-size: 17px;
  font-weight: 400;
}
.townSession {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}
.videomodal .modal-dialog {
  width: 70%;
}
.reprendre {
  margin-top: 50px;
}
.card {
  min-height: 103px;
}
.card .card-title {
  font-size: 16px !important;
}
.titre-methode-vente p {
  font-size: 16px;
  color: var(--white-color);
  font-weight: 400;
}
.body-details {
  background: linear-gradient(180deg, #151c25 70%, #151c2500 30%, rgba(9, 9, 121, 0) 63%);
}
.body-details .bg-details {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  height: 265px;
}
.box-details {
  margin-top: 50px;
  padding: 30px;
  background-color: #151c25;
}
.details .card-body {
  background-color: #1e2e45 !important;
  min-height: 103px;
}
.card-img-top {
  border-radius: 0 !important;
}
.body-details .search-title img,
.box-details .search-title img {
  width: 18px;
}
.body-details .border-end {
  border-right: 1px solid #ffffff2b !important;
}
.detailsSession .box-details {
  height: calc(100% - 25px);
  padding: 50px;
}
.textBox {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: white;
}
.bottomRight {
  /* position: absolute; */
  bottom: 0;
  /* right: 50px;
  float:right; */
}

@media screen and(max-width:1024px) {
  .body-details .rest {
    flex-direction: column !important;
  }
  .body-details .chartContainer {
    max-width: 67% !important;
  }
  .body-details .imgDetail {
    width: 100% !important;
  }
  .body-details .search-title {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 991px) {
  .body-details {
    background: linear-gradient(180deg, #151c25 86%, #151c2500 30%, rgba(9, 9, 121, 0) 63%);
  }
}
@media only screen and (max-width: 768px) {
  .body-details {
    background: linear-gradient(180deg, #151c25 86%, #151c2500 30%, rgba(9, 9, 121, 0) 63%);
  }
}