.card , .cardUnit{
   border: none!important;
    border-radius: 0!important;  
 }
 .card:hover .card-body{
  background-color: var(--primary-color) !important;
 }
 .card-img-top{
   border-radius: 0!important;  
 }
 .card-body{
   background-color: var(--black-color);
 
 }
 .reprendre{
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 30px;
 }
 .react-card-flip{
  margin-bottom: 20px;
 }
 .userI.card-body{
  background-color:#1E2E45 ;
  min-height: 90px;

}
.titleBack{
  font-family: Montserrat;
font-size: 16px;
font-weight: 600;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
color:#fff;

}
.textBack{
  color:#fff; 
font-family: Montserrat;
font-size: 16px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0em;
text-align: left;

}
.cardUnit{
  min-height: 103px;
}
 .card .card-title , .cardUnit .card-title{
   width: 95%;
   color: var(--white-color);
   font-size: 20px;
 
 }
 .item{
  margin-bottom: 20px;
 }
 .fleche-right{
   float: right;
   color: var(--primary-color);
 }
 .card:hover .fleche-right svg path{
   stroke: var(--white-color);
 }
 .count {
   padding: 15px;
   background-color: #212730;
   position: absolute;
   z-index: 999;
   /* width: 99px; */
   height: 137px;
   left: 0;
   top: -50px;
   color: var(--bs-blue);
   font-size: 88px;
   text-align: center;
   font-weight: bolder;
   z-index: 1;
 }
 .card .icon,.cardUnit .icon {
  padding: 7px;
  background-color: #212730;
  position: absolute;
  z-index: 999;
  width: 45px;
  height: 45px;
  right: 0;
  top: 0;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  font-weight: bolder;
 }
 .card .icon svg, .cardUnit .icon svg{
   float:none;
   color: white;
 }
 @media only screen and (max-width: 992px) {
  .card, .cardUnit{
    margin-bottom: 35px;
  }
  .count {
    padding: 4px;
    width: 53px;
    height: 69px;
    font-size: 42px;
    top: 0;
  }
}