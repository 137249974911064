.form-check-label{
    padding-left: 12px;
}
 .trainingTable{
    padding-right: 25px;
} 

@media screen and (max-width:767px) {
    .mtb-10{
        margin: 10px 0px;
    }
}
@media screen and (max-width:768px) {
    .none{
        display: none;
    }

}
@media screen and (min-width:424px) and (max-width:768px) {
   .form-label{
       height: 48px !important;
   }
   .square{
       margin-left: 5px !important;
   }
    
}
