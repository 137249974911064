.cost{
    background-image: url('../../../../assets/icons/euro.png') !important;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
}
.elearning.body-details{ 
    background: linear-gradient(180deg, #151c25 97%, #151c2500 30%, rgba(9,9,121,0) 63%);
  }
.elearning-square{
    background-color: rgb(30, 46, 69);
    margin: auto;
    border: #ffffff solid 1px;
    position: relative;
}
.elearning-bottom::after {
    content: '';
    height: 20px;
    width: 20px;
    position: absolute;
    background-color: rgb(30, 46, 69);
    bottom: -11px;
    left: 45%;
    border-top: #ffffff solid 1px;
    border-left: #ffffff solid 1px;
    transform: rotate(225deg);
  }
  .elearn .accordion-button::before{
  display: none !important;
 }
 .elearn .accordion-button::after{
  display: none !important;
 }
  .chart{
    padding: 10px 100px 20px;
  }

  .top-active {
    background-color: rgb(30, 46, 69)!important;
    margin: auto;
    border: #ffffff solid 1px;
    position: relative;
  }

  .top-active::after{
    content: '';
    height: 20px;
    width: 20px;
    position: absolute;
    background-color: rgb(30, 46, 69);
    bottom: -11px;
    left: 45%;
    border-top: #ffffff solid 1px;
    border-left: #ffffff solid 1px;
    transform: rotate(225deg);
  }

  #top-panels>div:hover{
    cursor: pointer;
  }

  .mr-50{
    margin-right: 50px;
  }
  .bottom-bordered{
    border-bottom: 1px solid #ccc;
  }

  #top-panels .card-body{
    height: 100%;
  }

  #show_parcours_associe{
    background-color: transparent!important;
    border: 0!important;
    color: #fff!important;
    padding: 0;
    background-image: none!important;
  }
  
