.liste .table{
  background-color: red !important;
}
.square a {
  padding: 6px;
}
.MuiDataGrid-columnSeparator--sideRight {
  display: none!important;
}
.square .btn{
  padding: 0;
}
.square a {
  padding: 6px;
}
.MuiDataGrid-columnSeparator--sideRight {
  display: none!important;
}
.selection-cell input[type=checkbox],
.selection-cell-header input[type=checkbox]{
  -webkit-appearance: none;
    background-color: var(--dark-color);
    width: 18px;
    height: 18px;
    border: 2px solid var(--primary-color);
    border-radius:2px;
    vertical-align: middle !important;
}
.selection-cell input[type="checkbox"]:checked{
  background-image:  url('../../../../assets/icons/Checkbox.png');
  background-repeat: no-repeat;
  background-position: center;
}
.pagination.react-bootstrap-table-page-btns-ul{
  float: right;
  margin-top: 23px;
}
.table.table td a.edit {
  color: #FFC107;
}
table.table td a.delete {
  color: #F44336;
}
