.accordion-item {
    background-color: #212730;
    padding: 0;
}

.accordion-item .accordion-header button{
    padding: 40px 0 30px 40px;
}


.duree_input{
    width: 100px;
    height: 50px;
    text-align: -webkit-center;
}

.text-right{
    text-align: right;
}

.font-weight-600{
    font-weight: 600;
}
.accordion-body{
    color: #fff;
}

.cursor-pointer{
    cursor: pointer;
}

#formulaire-edit-unite{
    color: #fff;
}

.top-50px{
    top: 50px!important;
}