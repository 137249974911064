.cost{
    background-image: url('../../../../assets/icons/euro.png') !important;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
}
.form-label{
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
}
.form-select{
    background-image: url('../../../../assets/icons/select.svg') !important;
}
.disabled{
  background-color: #3A4048 !important;  
  color: #808080 !important;
  font-weight: 600;
}
.resetBtn{
    text-decoration: none !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    font-family: 'Montserrat', sans-serif !important;
   
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.breadcrumb{
  margin-top:40px !important;
  margin-bottom: 20px !important;
}
