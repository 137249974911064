.form-check-label{
    padding-left: 12px;
}
.btn-gy{
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    padding: 12px 18px; 
}
/* .trainingTable{
    overflow: scroll !important;
} */

.form-switch .form-check-input:checked {
    background-position: left center;
   
}
.form-switch .form-check-input {
    
    background-position: right center;
  

}
@media screen and (max-width:1024px){
   /* .square{
        margin-left:4px !important;
        width:35px !important;
    }*/
}
@media screen and (max-width:767px) {
    .mtb-10{
        margin: 10px 0px;
    }
}
@media screen and (max-width:768px) {
    .none{
        display: none;
    }
    

}
@media screen and (min-width:424px) and (max-width:768px) {
   .form-label{
       height: 48px !important;
   }
   .square{
     /*  margin-left: 5px !important;*/
   }
    
}
